import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Components
import Seo from "../components/Seo"

interface IProps {
  content: {
    meta: {
      title: string
      description: string
      publishedDate: string
      modifiedDate: string
      lang: string
    }
    file: {
      url: string
      kind?: string
    }
  }
}

const InvestorsPage: React.FC<IProps> = ({ content }) => {
  useEffect(() => {
    if (
      content.file &&
      content.file.url &&
      content.file.kind === "document" &&
      document
    ) {
      const button = document.createElement("A") as HTMLAnchorElement
      button.href = content.file.url
      document.body.appendChild(button)
      button.click()
      document.body.removeChild(button)
    }
  }, [content.file])

  return (
    <Main>
      <Seo
        title={content.meta.title}
        description={content.meta.description}
        dateModified={content.meta.modifiedDate}
        datePublished={content.meta.publishedDate}
        lang={content.meta.lang}
        slug="investors"
        breadCrumbItems={[{ name: "Investors", url: "investors" }]}
      />
    </Main>
  )
}

const Main = styled.div`
  padding: 120px 0;
`

export const query = graphql`
  query investorsPage($locale: String!) {
    prismicHomePage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        # Meta
        meta_title
        meta_description
        # File
        investors_file {
          url
          raw
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const {
    data: raw,
    first_publication_date,
    last_publication_date,
    lang,
  } = data.prismicHomePage

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
    lang,
  }

  const content = {
    meta,
    file: {
      url: raw.investors_file.url,
      kind: raw.investors_file.raw?.kind,
    },
  }

  return <InvestorsPage content={content} />
}
